import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  required
} from 'react-admin'
import FeaturesInput from './Features'
import AutocompleteReferenceInput from '../AutocompleteReferenceInput'
import ResetPasswordButton from './ResetPasswordButton'
import validateExists from '../../validators/validateExists'

const toTimestamp = (value) => {
  if (value) {
    return new Date().toISOString()
  }

  return null
}

const toBoolean = (value) => {
  return !!value
}

const EditUser = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput
          source='username'
          validate={[required(), validateExists(props, 'username')]}
        />
        <TextInput
          source='email'
          validate={[required(), validateExists(props, 'email')]}
          type='email'
        />
        <FeaturesInput />
        <AutocompleteReferenceInput
          source='organization_id'
          reference='organization'
          optionText='name'
          label='Organization'
        />
        <BooleanInput
          source='deactivated_at'
          parse={toTimestamp}
          format={toBoolean}
          label='Deactivated?'
        />
        <BooleanInput
          source='email_confirmed_at'
          parse={toTimestamp}
          format={toBoolean}
          label='Email confirmed?'
        />
        <BooleanInput source='enable_jobs_list' label='Enable jobs list' />
        <ResetPasswordButton {...props} />
      </SimpleForm>
    </Edit>
  )
}

export default EditUser
